.footer{
        display: flex;
        justify-content: center;
        align-items: center;
        gap:3rem;
        flex-direction: column;
        padding-bottom: 10rem;

}

.footer>hr {
        border: 1px solid var(--orange);
        
     
        width: 100%;
       
}



.socialmedia{
        display:flex;
        flex-direction:row;
        justify-content: center;
        align-items: center;
        gap: 5rem;
        padding-top:5rem;
       
}

.socialmedia>img{
        width:2rem;
        height: 2rem;
}



.logo>img{

        width: 10rem;
       
}

.blur-f-1{
        width: 26rem;
        right: 15%;
        bottom: 0;
        filter: blur(200px);
        background-color: red;
}
.blur-f-2 {
        width: 26rem;
        right: 15%;
        bottom: 0;
        filter: blur(200px);
        background-color: red;
}
